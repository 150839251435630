import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import tw from 'twin.macro'

import '@styles/gutenberg.scss'
import Layout from '@partials/Layout'
import PageCover from '@components/PageCover'
import Seo from '@components/Seo'
import SinglePost from '@components/SinglePost'
import Section from '@components/Section'

import { WpPage } from '@_types/graphql-types'

type Home = {
  singlePage: WpPage
}

function page({ data: { singlePage } }: PageProps<Home>) {
  const pageCoverImage = singlePage.featuredImage?.node ? (
    <GatsbyImage
      image={singlePage.featuredImage.node.localFile.childImageSharp['full']}
      alt={singlePage.featuredImage.node.altText}
      loading="eager"
      objectFit="cover"
      tw="z-0 left-0 top-0 w-full h-full pointer-events-none"
    />
  ) : null

  return (
    <Layout
      headerCover={
        <PageCover
          type="header"
          title={singlePage.title}
          image={pageCoverImage}
        />
      }
    >
      <Seo
        title={singlePage.title}
        images={
          singlePage?.seo?.opengraphImage || singlePage?.featuredImage?.node
        }
        description={singlePage.seo.metaDesc}
        canonical={singlePage.seo.canonical}
        modified={singlePage.seo.opengraphModifiedTime}
        date={singlePage.seo.opengraphPublishedTime}
        type="page"
      />

      <Section>
        <div className="container px-4 py-24">
          <SinglePost content={singlePage?.content} />
        </div>
      </Section>
    </Layout>
  )
}

export default page

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    singlePage: wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          src: sourceUrl
          altText
          localFile {
            extension
            publicURL
            childImageSharp {
              full: gatsbyImageData(
                quality: 75
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      seo {
        ...SEO
      }
    }
  }
`
